import PriceCard from "./PriceCard";

function Pricing() {
  return (
    <section id="pricing" className="dark:bg-gray-900 py-24 md:py-36 2xl:py-52">
      <div className="container mx-auto px-6 ">
        <div className="space-y-7 md:px-10 lg:px-32 text-center">
          <h1 className="font-bold text-3xl md:text-5xl lg:text-6xl text-gray-900 dark:text-gray-50 tracking-wider">
            Plan & Pricing
          </h1>
          <p className="text-gray-500 dark:text-gray-400 text-sm md:text-base lg:text-lg tracking-wide">
            No matter how many team members you have - our pricing is simple,
            transparent and adapts to the size of your company.
          </p>
        </div>

        {/* Cards container */}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-12 mt-20">
          {/* Card */}
          <PriceCard
            title="Basic"
            price="Free"
            sub="up to 1 user"
            available={false}
            buttonText="Get Started"
            features={[
              "Real-time event analytics",
              "Profile customization",
              "No in app ads",
              "No download restrictions",
            ]}
          />
          <PriceCard
            title="Business"
            price="$9.99"
            sub="Per user / Per year"
            available={true}
            buttonText="Start 14 days free trial"
            primary={true}
            features={[
              "Real-time event analytics",
              "Profile customization",
              "No in app ads",
              "No download restrictions",
            ]}
          />
          <PriceCard
            title="Advance"
            price="$12.99"
            sub="Four users / Per year"
            available={true}
            buttonText="Start 14 days free trial"
            features={[
              "Real-time event analytics",
              "Profile customization",
              "No in app ads",
              "No download restrictions",
            ]}
          />
        </div>
      </div>
    </section>
  );
}

export default Pricing;
