import { useTheme } from "../store/themeContext";

import CheckmarkIcon from "../images/checkmark.svg";
import CloseIcon from "../images/close.svg";

function PriceCard({
  title,
  price,
  sub,
  available,
  features,
  buttonText,
  primary = false,
}) {
  const { isLightTheme } = useTheme();

  const icon = available ? CheckmarkIcon : CloseIcon;
  const borderColor = isLightTheme ? "#3B82F6" : "#374151";

  const primaryStyle = primary
    ? {
        boxShadow: "0 0px 60px 15px rgba(55, 125, 255, 0.1)",
        border: `2px solid ${borderColor}`,
      }
    : {};

  return (
    <div
      className="dark:bg-gray-800 relative p-8 rounded-lg border shadow border-gray-100 dark:border-gray-700"
      style={primaryStyle}>
      {primary && (
        <div className="absolute -top-4 -right-4 h-8 w-8">
          <span className="rounded-full absolute h-full w-full bg-blue-500"></span>
          {/* <span className="animate-ping absolute h-full w-full rounded-full bg-blue-500"></span> */}
        </div>
      )}
      <div className="flex flex-col space-y-4">
        <h2 className="font-bold text-3xl md:text-4xl text-gray-900 dark:text-gray-50 tracking-wider">
          {title}
        </h2>
        <h1 className="font-bold text-5xl xl:text-6xl text-gray-900 dark:text-gray-50 tracking-wider">
          {price}
        </h1>
        <p className="text-gray-500 dark:text-gray-400">{sub}</p>
      </div>

      <hr className="bg-gray-100 my-10" />

      <ul className="text-base text-gray-500 dark:text-gray-400">
        <li className="flex items-center mb-5">
          <img
            src={CheckmarkIcon}
            alt="checkmark-icon"
            loading="lazy"
            className="w-8"
          />
          <span className="ml-2">{features[0]}</span>
        </li>
        <li className="flex items-center mb-5">
          <img
            src={CheckmarkIcon}
            alt="checkmark-icon"
            loading="lazy"
            className="w-8"
          />
          <span className="ml-2">{features[1]}</span>
        </li>
        <li className="flex items-center mb-5">
          <img src={icon} alt="checkmark-icon" loading="lazy" className="w-8" />
          <span className="ml-2">{features[2]}</span>
        </li>
        <li className="flex items-center mb-5">
          <img src={icon} alt="checkmark-icon" loading="lazy" className="w-8" />
          <span className="ml-2">{features[3]}</span>
        </li>
      </ul>
      <button className="w-full font-bold bg-blue-500 text-blue-50 text-lg tracking-wide p-4 mt-10 rounded-lg">
        {buttonText}
      </button>
    </div>
  );
}

export default PriceCard;
