import Star from "../images/star.svg";

function CustomerCard({ image, name, job, comment, color }) {
  const cardColor = `bg-${color}-400`;

  return (
    <div className="relative">
      <div
        className={`absolute shadow-lg inset-0 ${cardColor} transform -rotate-6 rounded-3xl`}></div>
      <div className="relative bg-white dark:bg-gray-700 rounded-3xl flex flex-col items-center p-10 z-10">
        <div className="text-center -mt-24 mb-6">
          <div>
            <img
              src={image}
              alt={name}
              loading="lazy"
              className="w-28 border-8 border-gray-200 dark:border-gray-700 rounded-full"
            />
          </div>

          <h3 className="font-bold text-gray-800 dark:text-gray-50 my-1 2xl:text-xl">
            {name}
          </h3>
          <h4 className="text-gray-500 dark:text-gray-400">{job}</h4>
        </div>

        <p className="text-gray-500 dark:text-gray-300 2xl:text-lg">
          {comment}
        </p>

        <div className="flex w-full mt-7 space-x-2">
          <img className="w-7" src={Star} loading="lazy" alt="star" />
          <img className="w-7" src={Star} loading="lazy" alt="star" />
          <img className="w-7" src={Star} loading="lazy" alt="star" />
          <img className="w-7" src={Star} loading="lazy" alt="star" />
          <img className="w-7" src={Star} loading="lazy" alt="star" />
        </div>
      </div>
    </div>
  );
}

export default CustomerCard;
