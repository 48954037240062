import { useTheme } from "../store/themeContext";

import LightCheckmark from "../images/round-checkmark.svg";
import DarkCheckmark from "../images/dark-round-checkmark.svg";
import Smile from "../images/icon-smile.svg";
import HappyDude from "../images/person.webp";
import ArrowRight from "../images/arrow-circle-right.svg";

function Features() {
  const { isLightTheme } = useTheme();

  const checkmark = isLightTheme ? LightCheckmark : DarkCheckmark;

  return (
    <section id="features" className="py-24 md:py-36 lg:py-52 dark:bg-gray-900">
      <div className="container mx-auto px-4 md:px-8 lg:px-20 flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="space-y-6 lg:w-full">
          <h1 className="font-bold tracking-wider text-4xl lg:text-5xl xl:text-6xl text-gray-800 dark:text-gray-50">
            Everything you need in one place
          </h1>
          <p className="tracking-wide text-base lg:text-lg text-gray-600 dark:text-gray-400">
            Powerful metrics to better understand your business are right at
            your fingertips, once you start working with them!
          </p>

          <div className="grid md:grid-cols-2">
            <div className="flex items-center space-x-5 my-3 md:mr-3 lg:mr-0">
              <img
                src={checkmark}
                alt="checkmark"
                loading="lazy"
                className="w-10 bg-green-100 dark:bg-green-500 rounded-full"
              />
              <p className="text-gray-500 dark:text-gray-300 text-sm tracking-wide">
                Real time data
              </p>
            </div>
            <div className="flex items-center space-x-5 my-3 md:mr-3 lg:mr-0">
              <img
                src={checkmark}
                alt="checkmark"
                loading="lazy"
                className="w-10 bg-green-100 dark:bg-green-500 rounded-full"
              />
              <p className="text-gray-500 dark:text-gray-300 text-sm tracking-wide">
                Robust Workflow
              </p>
            </div>
            <div className="flex items-center space-x-5 my-3 md:mr-3 lg:mr-0">
              <img
                src={checkmark}
                alt="checkmark"
                loading="lazy"
                className="w-10 bg-green-100 dark:bg-green-500 rounded-full"
              />
              <p className="text-gray-500 dark:text-gray-300 text-sm tracking-wide">
                FDIC Insured
              </p>
            </div>
            <div className="flex items-center space-x-5 my-3 md:mr-3 lg:mr-0">
              <img
                src={checkmark}
                alt="checkmark"
                loading="lazy"
                className="w-10 bg-green-100 dark:bg-green-500 rounded-full"
              />
              <p className="text-gray-500 dark:text-gray-300 text-sm tracking-wide">
                Rest API enabled
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <h3 className="text-blue-500 text-xl tracking-wider mt-5">
              Explore new features
            </h3>
            <img
              src={ArrowRight}
              alt="arrow-icon"
              loading="lazy"
              className="w-8 mt-5"
            />
          </div>
        </div>

        <div className="relative mt-14 md:mt-0">
          <img src={HappyDude} alt="happydude" loading="lazy" />

          <div className="lg:px-4 xl:px-8 absolute bottom-4 right-0 md:bottom-20 lg:bottom-28 xl:bottom-32 flex items-center space-x-5 bg-white p-2 md:p-2 lg:p-4 rounded-lg shadow-2xl">
            <img src={Smile} alt="smile-icon" loading="lazy" />

            <div className="space-y-4 ">
              <p className="text-gray-500 text-lg">Notifications</p>
              <p className="text-gray-800">Try the messenger feature</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
