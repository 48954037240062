function Overlay({ show, setMenuStatus }) {
  return (
    <>
      {show && (
        <div
          onClick={() => setMenuStatus(false)}
          className="absolute inset-0 w-screen h-screen bg-gray-700 opacity-50 z-10"></div>
      )}
    </>
  );
}

export default Overlay;
