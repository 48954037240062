import OfferCard from "./OfferCard";
import { useTheme } from "../store/themeContext";

import Analytics from "../images/icon-analytics.svg";
import AnalyticsDark from "../images/icon-analytics-dark.svg";
import Evolution from "../images/icon-evolution.svg";
import EvolutionDark from "../images/icon-evolution-dark.svg";
import Execution from "../images/icon-execution.svg";
import ExecutionDark from "../images/icon-execution-dark.svg";
import Planning from "../images/icon-planning.svg";
import PlanningDark from "../images/icon-planning-dark.svg";
import Results from "../images/icon-results.svg";
import ResultsDark from "../images/icon-results-dark.svg";
import Storage from "../images/icon-storage.svg";
import StorageDark from "../images/icon-storage-dark.svg";

function Offers() {
  const { isLightTheme } = useTheme();

  // icons
  const planningIcon = isLightTheme ? Planning : PlanningDark;
  const executionIcon = isLightTheme ? Execution : ExecutionDark;
  const resultsIcon = isLightTheme ? Results : ResultsDark;
  const evolutionIcon = isLightTheme ? Evolution : EvolutionDark;
  const storageIcon = isLightTheme ? Storage : StorageDark;
  const analyticsIcon = isLightTheme ? Analytics : AnalyticsDark;

  return (
    <section className="bg-gray-200 dark:bg-gray-800 py-24 md:py-36 lg:py-52">
      <main className="container mx-auto px-4 md:px-22 lg:px-24 2xl:px-44">
        <div className="text-center space-y-6 lg:space-y-12">
          <h1 className="font-bold text-3xl md:text-6xl lg:text-7xl 2xl:text-8xl text-gray-700 dark:text-gray-50 tracking-wider">
            What gulpie offers
          </h1>
          <p className="text-sm md:text-base lg:text-xl 2xl:text-3xl text-gray-600 dark:text-gray-400">
            Learn all the features to make your process even faster and more
            exciting. Our product is ready to give you a pleasant interaction
            experience.
          </p>
        </div>

        <div className="mt-20 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
          <OfferCard
            icon={planningIcon}
            alt="planning-icon"
            title="Flexible planning"
            text="The rich features of our platform make it easy for your team member to plan with your product."
          />
          <OfferCard
            icon={executionIcon}
            alt="execution-icon"
            title="Transparent execution"
            text="Our software brings transparency to your work processes and the entire ecosystem."
          />
          <OfferCard
            icon={resultsIcon}
            alt="results-icon"
            title="Actionable results"
            text="Extensive reporting functionality gives your team critical insight into their agile process."
          />
          <OfferCard
            icon={evolutionIcon}
            alt="evolution-icon"
            title="Scalable evolution"
            text="Move software helps people in your team work with project resolution without losing quality."
          />
          <OfferCard
            icon={storageIcon}
            alt="storage-icon"
            title="Cloud storage"
            text="You can store your projects without leaving our product. Move Cloud will help you do this."
          />
          <OfferCard
            icon={analyticsIcon}
            alt="analytics-icon"
            title="Analytics API"
            text="When working with metrica our API manager can help you connect it safely and get the latest statistics."
          />
        </div>
      </main>
    </section>
  );
}

export default Offers;
