import ThemeContextProvider from "./store/themeContext";
import Layout from "./container/Layout";

function App() {
  return (
    <ThemeContextProvider>
      <Layout />
    </ThemeContextProvider>
  );
}

export default App;
