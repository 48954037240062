import TabletImg from "../images/tablet-mockup.webp";
import Aven from "../images/aven.png";
import Goldline from "../images/goldline.png";
import Kyan from "../images/kyan.png";
import Kanba from "../images/kanba.png";
import Invision from "../images/invision.png";
import Spotify from "../images/spotify.png";

function Companies() {
  return (
    <section>
      <div className="bg-white dark:bg-gray-900 w-full h-20 md:h-40 -mt-5"></div>

      <div>
        <div className="px-10 md:px-14 lg:px-20 bg-gray-200 dark:bg-gray-800 flex flex-col items-center justify-center">
          <img
            src={TabletImg}
            alt="tablet mockup"
            loading="lazy"
            className="-mt-12 md:-mt-24"
          />
          <p className="text-center mt-10 text-gray-400 ">
            We are trusted by the best in the world
          </p>

          <div className="container mx-auto px-6 my-16 flex flex-wrap md:flex-nowrap  items-center justify-between gap-y-4 gap-x-2 md:gap-y-0">
            <img
              src={Invision}
              alt="Invision"
              loading="lazy"
              className="w-20  lg:w-28 xl:w-32"
            />
            <img src={Aven} alt="Aven" className="w-20  lg:w-28 xl:w-32" />
            <img
              src={Goldline}
              alt="Goldline"
              loading="lazy"
              className="w-20  lg:w-28 xl:w-32"
            />
            <img
              src={Kanba}
              alt="Kanba"
              loading="lazy"
              className="w-20  lg:w-28 xl:w-32"
            />
            <img
              src={Kyan}
              alt="Kyan"
              loading="lazy"
              className="w-20  lg:w-28 xl:w-32"
            />
            <img
              src={Spotify}
              alt="Spotify"
              loading="lazy"
              className="w-20  lg:w-28 xl:w-32"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Companies;
