function Newsletter() {
  return (
    <section id="newsletter" className="dark:bg-gray-900">
      <div className="container mx-auto px-5 md:px-14 py-40">
        <div className="bg-gradient-to-r from-blue-600 to-blue-500 rounded-3xl flex flex-col items-center p-8 md:p-20">
          <h3 className="font-bold text-blue-50 text-3xl lg:text-5xl text-center tracking-wider mb-5">
            Signup to get our latest updates!
          </h3>
          <p className="text-white text-center text-base lg:text-xl tracking-wide mb-7">
            Get pro tips to building amazing data visualizations in minutes.
          </p>

          <form className="flex flex-col md:flex-row mt-5 w-full justify-center">
            <input
              placeholder="Enter your email"
              type="email"
              className="rounded-md text-lg xl:text-2xl md:pr-11 border-transparent focus:ring-2 focus:ring-opacity-60 focus:ring-white"
            />
            <button
              type="submit"
              className="font-bold bg-gray-800 hover:bg-gray-900 text-gray-50 text-lg xl:text-2xl py-3 md:py-4 md:px-12 xl:py-5 mt-5 md:mt-0 md:ml-5 rounded-lg transition-colors duration-300 cursor-pointer">
              Notify me
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
