import { useTheme } from "../store/themeContext";

import SmartPhone from "../images/analytics-mobile.webp";
import MobileBg from "../images/mobile-background.svg";
import Download from "../images/icon-download.svg";
import DownloadDark from "../images/icon-download-dark.svg";
import Smile from "../images/icon-smile.svg";
import SmileDark from "../images/icon-smile-dark.svg";

function Analytics() {
  const { isLightTheme } = useTheme();

  const smileIcon = isLightTheme ? Smile : SmileDark;
  const downloadIcon = isLightTheme ? Download : DownloadDark;

  return (
    <section className="py-24 md:py-36 lg:py-52 dark:bg-gray-900">
      <main className="flex flex-col md:flex-row md:space-x-14 lg:items-center xl:space-x-28 container mx-auto px-4 md:px-8 lg:px-20">
        <div className="relative md:mt-10 lg:mt-8 xl:mt-0">
          <img src={MobileBg} alt="mobile-background" loading="lazy" />
          <div className="absolute -top-24 left-6 md:-top-20 md:left-5 lg:-top-24 2xl:-top-36">
            <img src={SmartPhone} alt="smartphone" loading="lazy" />
          </div>
        </div>

        <div className="mt-28 md:-mt-28 lg:mt-0">
          <div>
            <h1 className="font-bold text-4xl lg:text-5xl xl:text-6xl text-gray-800 dark:text-gray-50 tracking-widest mb-5">
              Analytics at your fingertips
            </h1>
            <p className="text-gray-500 dark:text-gray-300 xl:text-lg">
              You can track all transactions in special statistics that will
              show the ratio of spending to your account balance.
            </p>

            <div className="mt-10 space-y-8">
              <div className="flex items-center space-x-6 p-5 rounded-lg shadow-lg dark:bg-gray-800">
                <div>
                  <img
                    src={smileIcon}
                    loading="lazy"
                    alt="smile-icon"
                    className="w-32"
                  />
                </div>

                <div className="space-y-2">
                  <h3 className="font-bold text-gray-700 dark:text-gray-100 text-2xl tracking-wider">
                    We adapt to you
                  </h3>
                  <p className="text-gray-500 dark:text-gray-300 text-sm">
                    You can have all the power of gulpie analytics from you
                    favourite mobile device.
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-6 p-5 rounded-lg shadow-lg dark:bg-gray-800">
                <div>
                  <img
                    src={downloadIcon}
                    alt="download-icon"
                    loading="lazy"
                    className="w-32"
                  />
                </div>

                <div className="space-y-2">
                  <h3 className="font-bold text-gray-700 dark:text-gray-100 text-2xl tracking-wider">
                    Download to your smartphone
                  </h3>
                  <p className="text-gray-500 dark:text-gray-300 text-sm">
                    Users of the gulpie subscription can download analytics for
                    free to their smartphone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}

export default Analytics;
