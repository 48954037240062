import Header from "../components/Header";
import Hero from "../components/Hero";
import Companies from "../components/Companies";
import Features from "../components/Features";
import Offers from "../components/Offers";
import Analytics from "../components/Analytics";
import Customers from "../components/Customers";
import { useTheme } from "../store/themeContext";
import Pricing from "../components/Pricing";
import Newsletter from "../components/Newsletter";

function Layout() {
  const { isLightTheme } = useTheme();

  return (
    <div className={!isLightTheme ? "dark" : ""}>
      <Header />
      <Hero />
      <Companies />
      <Features />
      <Offers />
      <Analytics />
      <Customers />
      <Pricing />
      <Newsletter />
    </div>
  );
}

export default Layout;
