import CustomerCard from "./CustomerCard";

import CricleArrow from "../images/arrow-circle-right.svg";

function Customers() {
  return (
    <section className="bg-gray-200 dark:bg-gray-800 py-24 md:py-36 2xl:py-52">
      <main className="container mx-auto px-4 ">
        <h1 className="font-bold text-center text-gray-800 dark:text-gray-50 text-3xl md:text-5xl 2xl:text-7xl tracking-wider mb-32 xl:mb-40 2xl:mb-44">
          Customers are loving gulpie
        </h1>

        <div className="grid grid-cols-1 gap-y-24 md:grid-cols-2 md:gap-y-20 md:gap-x-10  lg:grid-cols-3">
          <CustomerCard
            image="https://i.pravatar.cc/300?img=12"
            name="Martin Doe"
            job="Developer"
            color="yellow"
            comment="With the gulpie messenger I can correspond with clients from all over the world and never forget about important meetings."
          />
          <CustomerCard
            image="https://i.pravatar.cc/300?img=22"
            name="Susan Doe"
            job="Designer"
            color="blue"
            comment="When we first met gulpie, we were skeptical. We tried lots of things before and none of them worked. But, when we tested the Shape..."
          />
          <CustomerCard
            image="https://i.pravatar.cc/300?img=31"
            name="Maya Doe"
            job="Junior Analyst"
            color="purple"
            comment="I feel grateful that Simple pushed me to expand my horizons and strive to accomplish more. When we first met Appy, we were..."
          />
        </div>

        <div className="mt-20 md:mt-32 flex items-center space-x-3">
          <p className="text-xl md:text-2xl text-blue-600">
            See all testimonials
          </p>
          <img
            src={CricleArrow}
            alt="arrow-icon"
            loading="lazy"
            className="w-8"
          />
        </div>
      </main>
    </section>
  );
}

export default Customers;
