import { useToggle } from "../hooks/useToggle";
import { useTheme } from "../store/themeContext";
import Overlay from "./Overlay";

import Logo from "../images/logo.svg";
import DarkLogo from "../images/dark-logo.svg";
import Sun from "../images/sun.svg";
import Moon from "../images/moon.svg";
import Menu from "../images/menu.svg";
import DarkMenu from "../images/dark-menu.svg";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useToggle(false);
  const { isLightTheme, toggleTheme } = useTheme();

  const logo = isLightTheme ? Logo : DarkLogo;
  const menu = isLightTheme ? Menu : DarkMenu;

  const themeHandler = () => {
    isLightTheme ? toggleTheme("dark") : toggleTheme("light");
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <header
      className={
        isLightTheme
          ? "fixed top-0 left-0 w-full bg-white shadow z-50"
          : "fixed top-0 left-0 w-full shadow dark dark:bg-gray-900 z-50"
      }>
      <Overlay show={isMenuOpen} setMenuStatus={setIsMenuOpen} />
      <div className="container mx-auto px-4 lg:px-20 2xl:px-0 flex h-20 items-center justify-between">
        <div className="cursor-pointer">
          <img className="w-36" src={logo} alt="Gulpie" onClick={scrollToTop} />
        </div>

        {/* Mobile nav */}
        <div className="md:hidden no-animate" onClick={setIsMenuOpen}>
          <img className="w-10" src={menu} alt="Menu" />
        </div>

        {isMenuOpen ? (
          <div className="bg-white dark:bg-gray-900 h-screen w-3/4 absolute top-0 left-0 shadow-2xl z-20">
            <div className="container mx-auto px-11 py-3 flex flex-col">
              <div className="flex justify-between">
                <div className="mb-16">
                  <img
                    className="inline-block w-28 mt-6"
                    src={logo}
                    alt="Gulpie"
                    onClick={scrollToTop}
                  />
                </div>

                <div>
                  <img
                    src={isLightTheme ? Moon : Sun}
                    alt="Toggle Theme"
                    className="w-8 mt-6"
                    onClick={themeHandler}
                  />
                </div>
              </div>

              <ul className="space-y-4 text-lg text-gray-500">
                <li>
                  <a href="#home" className="active:text-black">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#features" className="active:text-black">
                    Features
                  </a>
                </li>
                <li>
                  <a href="#pricing" className="active:text-black">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#newsletter" className="active:text-black">
                    Newsletter
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : null}

        {/* Desktop nav */}
        <nav className="hidden md:block">
          <ul className="flex space-x-11 tracking-wide xl:text-xl text-gray-500 dark:text-gray-400">
            <li>
              <a
                href="#home"
                className="hover:text-gray-800 dark:hover:text-gray-600 transition-colors duration-300">
                Home
              </a>
            </li>
            <li>
              <a
                href="#features"
                className="hover:text-gray-800 dark:hover:text-gray-600 transition-colors duration-300">
                Features
              </a>
            </li>
            <li>
              <a
                href="#pricing"
                className="hover:text-gray-800 dark:hover:text-gray-600 transition-colors duration-300">
                Pricing
              </a>
            </li>
            <li>
              <a
                href="#newsletter"
                className="hover:text-gray-800 dark:hover:text-gray-600 transition-colors duration-300">
                Newsletter
              </a>
            </li>
          </ul>
        </nav>

        <div className="hidden md:flex space-x-4 bg-blue-100 py-3 px-7 rounded-full">
          <div
            className="flex justify-between items-center space-x-2 cursor-pointer"
            onClick={themeHandler}>
            <div>
              <img
                className={isLightTheme ? "w-4" : "w-4 opacity-50"}
                src={Sun}
                alt="sun"
              />
            </div>
            <div
              className={
                isLightTheme ? "text-blue-600" : "text-blue-600 opacity-50"
              }>
              Light
            </div>
          </div>
          <div
            className="flex justify-between items-center space-x-2 cursor-pointer"
            onClick={themeHandler}>
            <div
              className={
                isLightTheme ? "text-blue-600 opacity-50" : "text-blue-600"
              }>
              Dark
            </div>
            <div>
              <img
                className={isLightTheme ? "w-4 opacity-50" : "w-4"}
                src={Moon}
                alt="Moon"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
