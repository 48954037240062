import { createContext, useContext, useState } from "react";

const storedTheme = JSON.parse(localStorage.getItem("theme")) || "light";
const isLight = storedTheme === "light" ? true : false;

const ThemeContext = createContext();

function ThemeProvider(props) {
  const [isLightTheme, setIsLightTheme] = useState(isLight);

  const toggleTheme = theme => {
    setIsLightTheme(!isLightTheme);

    if (theme) {
      localStorage.setItem("theme", JSON.stringify(theme));
    }
  };

  const context = { isLightTheme, toggleTheme };

  return <ThemeContext.Provider value={context} {...props} />;
}

export function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return context;
}

export default ThemeProvider;
