function OfferCard({ icon, alt, title, text }) {
  return (
    <div className=" group bg-white bg-gradient-to-br hover:from-blue-500 hover:to-blue-600 dark:bg-gray-700 transform hover:scale-105 hover:-rotate-3 transition duration-300 p-9 rounded-lg shadow-lg">
      <img src={icon} alt={alt} loading="lazy" className="w-16 mb-6" />
      <h3 className="font-bold text-gray-700 group-hover:text-blue-50 dark:text-gray-50 text-2xl tracking-wider mb-4">
        {title}
      </h3>
      <p className="text-gray-500 group-hover:text-blue-100 dark:text-gray-300 2xl:text-lg">
        {text}
      </p>
    </div>
  );
}

export default OfferCard;
