import Github from "../images/github.svg";

function Hero() {
  return (
    <section className="pt-14 md:pt-24 lg:pt-32 dark:bg-gray-900">
      <div
        id="home"
        className="container mx-auto w-full px-3 md:px-14 lg:px-24 xl:px-64 py-28">
        <div className="flex flex-col items-center justify-between text-center">
          <h1 className="font-bold text-3xl md:text-7xl tracking-wider text-gray-800 dark:text-gray-100">
            Gulpie is the perfect development tool
          </h1>
          <div className="container mx-auto px-4 my-5">
            <p className="py-5 text-sm  md:text-xl text-gray-500 dark:text-gray-400">
              We have created software for each employee of your team to help
              them do their work more efficiently and better to plan, track and
              release great software!
            </p>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-8">
            <div className="flex items-center px-14 md:px-20 py-4  bg-blue-100 dark:bg-gray-600 rounded-lg space-x-5 cursor-pointer hover:bg-blue-200 transition-color duration-300">
              <img src={Github} alt="Github" className="w-8 inline-block" />
              <p className="text-blue-500 dark:text-gray-300 text-lg md:text-xl font-bold select-none">
                View on Github
              </p>
            </div>
            <div className="bg-blue-500 px-20 py-4 md:py-5  rounded-lg cursor-pointer hover:bg-blue-600 transition-color duration-300">
              <p className="text-blue-50 text-xl font-bold select-none">
                Sign in
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
